import React from 'react'
import Link from '../Link'

interface IGettingStartedCardProps {
  image: JSX.Element
  title: string
  buttonText: string
  link: string
}

const GettingStartedCard: React.FC<IGettingStartedCardProps> = ({
  image,
  title,
  buttonText,
  link,
}) => {
  return (
    <article className="getting-started-card">
      <div className="getting-started-card-top">{image}</div>
      <div className="getting-started-card-bottom">
        <div className="getting-started-card-bottom-wrap">
          <h3>{title}</h3>
          <Link
            className="md-button md-button--36 getting-started-card-see-more-btn"
            to={link}
          >
            {buttonText}
          </Link>
        </div>
      </div>
    </article>
  )
}

export default GettingStartedCard
